<template>
    <a-spin :spinning="spinning">
        <div class="modal-header">
            <div class="modal-title" style="width: 100%; text-align: left">
                <span v-if="id">新增报备单</span>
                <span v-if="!id">编辑报备单</span>
            </div>
        </div>
        <a-form :form="form" @submit="handleSubmit" layout="horizontal">
            <div class="formScroll">
                <a-row :gutter="24">
                    <a-col span="12">
                        <!--控件名称-->
                        <a-form-item :label="l('报备单号')">
                            <a-input :placeholder="l('报备单号')"
                                     v-decorator="[ 'OrderNo',{
                                        rules: [
                                          { required: true, message: l('validation.required') },
                                          { max: 50, message: l('validation.maxlength') },
                                        ],
                                      },
                                    ]" />
                        </a-form-item>
                    </a-col>
                    <a-col span="12">
                        <!--显示文本-->
                        <a-form-item :label="l('所属企业名称')">
                            <a-input :placeholder="l('所属企业名称')"
                                     v-decorator="['EnterpriseName',{
                                        rules: [
                                          { required: true, message: l('validation.required') },
                                          { max: 30, message: l('validation.maxlength') },
                                        ],
                                      },
                                    ]" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col span="12">
                        <a-form-item :label="l('AppID')">
                            <a-input :placeholder="l('AppID')"
                                     v-decorator="[ 'AppId',{
                                        rules: [
                                          { required: true, message: l('validation.required') },
                                          { max: 50, message: l('validation.maxlength') },
                                        ],
                                      },
                                    ]" />
                        </a-form-item>
                    </a-col>
                    <a-col span="12">
                        <a-form-item :label="l('App秘钥')">
                            <a-input :placeholder="l('AppSecret')"
                                     v-decorator="[ 'AppSecret',{
                                        rules: [
                                          { required: true, message: l('validation.required') },
                                          { max: 50, message: l('validation.maxlength') },
                                        ],
                                      },
                                    ]" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col span="24">
                        <a-form-item label="所属行业">
                            <a-input :placeholder="l('所属行业')"
                                     v-decorator="['IndustryName',{ rules: [] }]" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col span="24">
                        <a-form-item label="行业说明">
                            <a-textarea v-decorator="['IndustryDescription',{ rules: [] }]" :row="4" placeholder="行业说明"></a-textarea >
                        </a-form-item>
                    </a-col>
                </a-row>
            </div>
            <a-row :gutter="24">
                <a-col span="24">
                    <a-form-item class="btn--container" style="text-align: center">
                        <a-button type="button" @click="close()">
                            {{ l("Cancel") }}
                        </a-button>
                        <a-button :loading="loading" type="primary" html-type="submit">
                            {{ l("Save") }}
                        </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
    </a-spin>
</template>
<script>
    import { ModalComponentBase } from "@/shared/component-base";
    import {message} from "ant-design-vue";
    import {ModalHelper} from "@/shared/helpers";   
    import {AppConsts} from "@/abpPro/AppConsts";
    import {fileDownloadService} from "@/shared/utils";

    export default {
        mixins: [ModalComponentBase],
        name: 'fieldResourceEdit',
        data() {
            return {
                ComponentList: [],
                ShowOptions: false,
                firstOptionValue: '',
                FieldOptions: [],
                currentOptionId: 0,
                spinning: false
            };
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: "register" });
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            if(this.id) {
                this.getData();
            }
        },
        methods: {
            /**
             * 提交表单
             */
            async handleSubmit(e) {
                e.preventDefault();
                this.form.validateFieldsAndScroll(async (err, values) => {
                    if (!err) {
                        this.loading = true
                        let data = {
                            IndustryName: values.IndustryName,
                            IndustryDescription: values.IndustryDescription,
                            OrderNo: values.OrderNo,
                            EnterpriseName: values.EnterpriseName,
                            id: this.id ? this.id : null,
                            AppId: values.AppId,
                            AppSecret: values.AppSecret
                        };
                        let url = data.id && data.id != null ? "/api/services/app/SohoIndustry/UpdateSohoIndustry" : "/api/services/app/SohoIndustry/AddSohoIndustry";
                        let _this = this;
                        this.SendToService({
                            url: url,
                            method: 'POST',
                            data: data,
                            success: (res) => {
                                this.$message.success(`操作成功`);
                                this.success(true);
                            },
                            complete: (e) => {
                                _this.loading = false;
                            }
                        });
                    }
                });
            },
            SendToService(opts) {
                this.spinning = true;
                let options = {
                    url: this.$apiUrl + opts.url,
                    method: opts.method,
                    data: opts.data,
                    params: opts.params,
                    headers: { "Content-Type": "application/json-patch+json" },
                };
                this.$api.request(options).then((res) => {
                    this.spinning = false;
                    if(opts.complete && typeof opts.complete == 'function') {
                        opts.complete(res);
                    }
                    if (res.status == 200) {
                        if (opts.success && typeof opts.success == 'function') {
                            opts.success(res.data);
                        }
                    } else {
                        abp.message.error(res.statusText);
                    }
                }).catch(e => {
                    console.error(e);
                    this.spinning = false;
                    if(opts.complete && typeof opts.complete == 'function') {
                        opts.complete(e);
                    }
                });
            },
            getData() {
                let _this = this;
                let data = {
                    id: this.id
                };
                this.SendToService({
                    url: '/api/services/app/SohoIndustry/GetById',
                    method: 'get',
                    params: data,
                    success: (res) => {
                        this.form.setFieldsValue({
                            IndustryName: res.industryName,
                            IndustryDescription: res.industryDescription,
                            OrderNo: res.orderNo,
                            EnterpriseName: res.enterpriseName,
                            AppId: res.appId,
                            AppSecret: res.appSecret
                        });
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .btn--container .ant-form-item-children {
        display: block;
        text-align: center;
    }

    .pleaseSelect-text {
        font-size: 14px;
        padding: 0 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 0;
    }

    .ant-cascader-picker {
        width: 85% !important;
    }

    .ant-btn-primary {
        margin-left: 10px;
    }

    .formScroll {
        max-height: 600px;
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE 10+ */
        scrollbar-width: none; /* Firefox */
    }

    .dianmi-select {
        width: 40%;
        margin-left: 5px;
    }

    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
</style>