var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title",staticStyle:{"width":"100%","text-align":"left"}},[(_vm.id)?_c('span',[_vm._v("新增报备单")]):_vm._e(),(!_vm.id)?_c('span',[_vm._v("编辑报备单")]):_vm._e()])]),_c('a-form',{attrs:{"form":_vm.form,"layout":"horizontal"},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"formScroll"},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('报备单号')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'OrderNo',{
                                    rules: [
                                      { required: true, message: _vm.l('validation.required') },
                                      { max: 50, message: _vm.l('validation.maxlength') } ],
                                  } ]),expression:"[ 'OrderNo',{\n                                    rules: [\n                                      { required: true, message: l('validation.required') },\n                                      { max: 50, message: l('validation.maxlength') },\n                                    ],\n                                  },\n                                ]"}],attrs:{"placeholder":_vm.l('报备单号')}})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('所属企业名称')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['EnterpriseName',{
                                    rules: [
                                      { required: true, message: _vm.l('validation.required') },
                                      { max: 30, message: _vm.l('validation.maxlength') } ],
                                  } ]),expression:"['EnterpriseName',{\n                                    rules: [\n                                      { required: true, message: l('validation.required') },\n                                      { max: 30, message: l('validation.maxlength') },\n                                    ],\n                                  },\n                                ]"}],attrs:{"placeholder":_vm.l('所属企业名称')}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('AppID')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'AppId',{
                                    rules: [
                                      { required: true, message: _vm.l('validation.required') },
                                      { max: 50, message: _vm.l('validation.maxlength') } ],
                                  } ]),expression:"[ 'AppId',{\n                                    rules: [\n                                      { required: true, message: l('validation.required') },\n                                      { max: 50, message: l('validation.maxlength') },\n                                    ],\n                                  },\n                                ]"}],attrs:{"placeholder":_vm.l('AppID')}})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('App秘钥')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'AppSecret',{
                                    rules: [
                                      { required: true, message: _vm.l('validation.required') },
                                      { max: 50, message: _vm.l('validation.maxlength') } ],
                                  } ]),expression:"[ 'AppSecret',{\n                                    rules: [\n                                      { required: true, message: l('validation.required') },\n                                      { max: 50, message: l('validation.maxlength') },\n                                    ],\n                                  },\n                                ]"}],attrs:{"placeholder":_vm.l('AppSecret')}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"24"}},[_c('a-form-item',{attrs:{"label":"所属行业"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['IndustryName',{ rules: [] }]),expression:"['IndustryName',{ rules: [] }]"}],attrs:{"placeholder":_vm.l('所属行业')}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"24"}},[_c('a-form-item',{attrs:{"label":"行业说明"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['IndustryDescription',{ rules: [] }]),expression:"['IndustryDescription',{ rules: [] }]"}],attrs:{"row":4,"placeholder":"行业说明"}})],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"24"}},[_c('a-form-item',{staticClass:"btn--container",staticStyle:{"text-align":"center"}},[_c('a-button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"loading":_vm.loading,"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.l("Save"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }