<template>
    <a-spin :spinning="spinning">
        <ele-table
            ref="rt"
            :columns="columns"
            :table-data="data"
            :total-items="totalItems"
            :actions-type="actionsType"
            :is-full="true"
            :current-page="pageNumber"
            :hide-row-selection="true"
            @emitRefreshData="clearFilterAndRefresh"
            @emitSortData="updateSortData"
            @emitSelectChange="updateSelectChange"
            @emitOnPageChange="updatePageChange"
            @emitShowSizeChange="updateShowSizeChange"
        >
            <!-- 查询 -->
            <a-row :gutter="8">
                <a-col class="gutter-row" :span="4">
                    <a-input v-model.trim="SearchDto.industryName"
                             :placeholder="l('请输入行业名称')"
                             allow-clear />
                </a-col>
                <a-col class="gutter-row" :span="4">
                    <a-button type="primary"
                              @click="getData()"
                              v-if="isGranted('Pages.SohoIndustry.Query')"> 
                              <!-- <a-icon type="search"></a-icon> -->
                        {{ l("Search") }}
                    </a-button>
                    <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                </a-col>
                <a-col class="gutter-row" :span="12" align="right">
                    <a-button v-if="isGranted('Pages.SohoIndustry.Create')" type="primary"
                              @click="addorEdit()">
                        <a-icon type="plus" />
                        <span>{{ l("Create") }}</span>
                    </a-button>
                    <!-- <a-button v-if="isGranted('Pages.SohoIndustry.Create')" type="default"
                              @click="syncOnlineOrder()">
                        <a-icon type="reload" />
                        <span>{{ l("同步线上报备单") }}</span>
                    </a-button> -->
                </a-col>
            </a-row>
        </ele-table>
    </a-spin>
</template>
<script>
    import { AppComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";
    import { CommonServiceProxy, initFieldLibOptions } from "@/shared/common-service";
    import { fileDownloadService } from "@/shared/utils";
    import moment from "moment";
    import EleTable from "@/components/ele-table";
    import ReactModify from "@/components/react-modify";
    import { AppConsts } from "@/abpPro/AppConsts";
    import { environment } from "@/environments/environment";
    import AddOrEditModal from './addoredit.vue';

    export default {
        mixins: [AppComponentBase],
        name: "sohoindustry-index",
        components: { EleTable },
        data() {
            return {
                spinning: false,
                selectedRowKeys: [],
                SearchDto: {
                    industryName: ''
                },
                // 总记录数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 总页数
                totalPages: 1,
                // 
                pagerange: [1, 1],
                // 每页显示数
                pageSizeOptions: ["10", "20", "30", "40"],
                request: { sorting: "", maxResultCount: 50, skipCount: 0 },
                // 表头
                columns: [],
                actionsType: null,
                // 数据
                data: [],
                ComponentList: []
            };
        },
        created() {
            this.getData();
            this.initActionsType();
            this.initColums();
        },
        methods: {
            initColums() {
                let _this = this;
                this.columns = [
                    {
                        title: "行业名称",
                        dataIndex: "industryName",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "industryName" },
                    },
                    {
                        title: "行业说明",
                        dataIndex: "industryDescription",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "industryDescription" },
                    },
                    {
                        title: "报备单号",
                        dataIndex: "orderNo",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "orderNo" }
                    },
                    {
                        title: "所属集团企业",
                        dataIndex: "enterpriseName",
                        sorter: false,
                        align: "center",
                        // width: 100,
                        scopedSlots: { customRender: "enterpriseName" }
                    },
                    {
                        title: "AppID",
                        dataIndex: "appId",
                        sorter: false,
                        align: "center",
                        // width: 100,
                        scopedSlots: { customRender: "appId" }
                    }
                ];
            },

            // 初始化列表action
            initActionsType() {
                let _this = this;
                let obj = {
                    type: "fieldResource",
                    isShow: true,
                    fns: {
                        detailList: [
                            {
                                granted: this.isGranted("Pages.SohoIndustry.Edit"),
                                name: this.l("Edit"),
                                icon: "profile",
                                width: "30%",
                                fn: (data) => {
                                _this.addorEdit(data);
                                },
                            },
                            {
                                granted: this.isGranted("Pages.SohoIndustry.Delete"),
                                width: "30%",
                                name: this.l("删除"),
                                icon: "close",
                                tipsDesc: "是否删除此数据?",
                                fn: (data) => {
                                    _this.delFieldResource(data.id);
                                },
                            }
                        ],
                        dropList: []
                    },
                };
                this.actionsType = obj;
            },
            getData () {
                let data = {
                    industryName: this.SearchDto.industryName,
                    sorting: this.request.sorting,
                    skipCount: this.request.skipCount,
                    maxResultCount: this.request.maxResultCount
                };
                this.$nextTick(() => {
                    this.$refs.rt.clearData();
                })
                let _this = this;
                this.SendToService({
                    url: '/api/services/app/SohoIndustry/GetPage',
                    method: 'POST',
                    data: data,
                    success(res) {
                        _this.data.splice(0, _this.data.length);
                        _this.totalItems = res.totalCount;
                        res.items.forEach(item => _this.data.push(item));
                        _this.data = res.items.map(item => {
                            return {
                                ...item
                            }
                        })
                    }
                });
            },
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            refreshGoFirstPage() {
                this.SearchDto.industryName = '';
                this.pageNumber = 1;
                this.request.SkipCount = 0;
                this.getData();
            },
            delFieldResource(id) {
                let _this = this;
                abp.message.confirm('是否删除此记录', (fn) => {
                    if(fn) {
                        _this.SendToService({
                            url: '/api/services/app/SohoIndustry/DeleteSohoIndustry',
                            method: 'get',
                            params: {id: id},
                            success(res) {
                                abp.message.success('删除成功');
                                _this.refreshGoFirstPage();
                            }
                        });
                    }
                });
            },
            addorEdit(data) {
                ModalHelper.create(AddOrEditModal, {
                    id: data ? data.id : undefined
                }, {
                    width: "800px",
                }).subscribe((res) => {
                    this.refreshGoFirstPage();
                });
            },
            SendToService(opts) {
                this.spinning = true;
                let options = {
                    url: this.$apiUrl + opts.url,
                    method: opts.method,
                    data: opts.data,
                    params: opts.params,
                    headers: { "Content-Type": "application/json-patch+json" },
                };
                this.$api.request(options).then((res) => {
                    this.spinning = false;
                    if (opts.complete && typeof opts.complete == 'function') {
                        opts.complete(res);
                    }
                    if (res.status == 200) {
                        if (opts.success && typeof opts.success == 'function') {
                            opts.success(res.data);
                        }
                    } else {
                        abp.message.error(res.statusText);
                    }
                }).catch(e => {
                    this.spinning = false;
                    if (opts.complete && typeof opts.complete == 'function') {
                        opts.complete(e);
                    }
                    console.error(e);
                });
            },
            /*
            分页相关
            */
            // 情况查询条件并重新查询
            clearFilterAndRefresh() {
                this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
                this.SearchDto.Name = "";
                this.SearchDto.FieldType = undefined;
                this.getData();
            },
            // 排序更新data
            updateSortData(newV) {
                let { columnKey, order } = newV;
                this.request.sorting = order ? `${columnKey} ${order}` : "";
                this.getData();
            },
            // table选择事件
            updateSelectChange(newV) {
                let { selectedRowKeys, selectedRows } = newV;
                this.onSelectChange(selectedRowKeys, selectedRows);
            },
            onSelectChange(keys, rows) {
                if(!!this.selectedRows){
                    this.selectedRows.splice(0, this.selectedRows.length);
                    for (let i = 0; i < rows.length; i++) {
                        this.selectedRows.push(rows[i]);
                    }
                }
            },
            // 分页
            updatePageChange(newV) {
                let { page, pageSize } = newV;
                this.onChange(page, pageSize);
            },
            onChange(page, pageSize) {
                this.pageNumber = page;
                this.request.SkipCount =
                    (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            updateShowSizeChange(newV) {
                debugger;
                let { current, size } = newV;
                this.showSizeChange(current, size);
            },
            showSizeChange(current, size) {
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            syncOnlineOrder(){
                let _this = this;
                this.SendToService({
                    url: '/api/services/app/SohoIndustry/SyncOnlineOrder',
                    method: 'get',
                    success(res) {
                        abp.message.success('同步成功');
                        _this.refreshGoFirstPage();
                    }
                });
            }
        }
    };
</script>

<style lang="less" scoped>
    .btn--container {
        margin-bottom: 20px;
    }

    .btn--footer {
        // border: 1px solid #e8e8e8;
        // margin: 20px;
        // padding: 20px;
        .table-delete

    {
        margin-left: 10px;
        margin-right: 10px;
    }

    }

    .btn--footer {
        margin-bottom: 20px;
    }
</style>
